// Import necessary modules and styles
import React from 'react';
import './Careers.css';  // Add specific styles for the Careers page
import officeImage from '../assets/images/story1.jpeg';  // Sample office image
import ResponsiveImage from '../components/ResponsiveImage.js'

const Careers = () => {
  return (
    <section className="careers">
      <div className="careers-header">
        <h1>Explore Careers at Ingrov Systems</h1>
        <p>Be part of a forward-thinking team that thrives on innovation, creativity, and growth.</p>
        <ResponsiveImage src={officeImage} alt="Ingrov Systems Office" className="office-image" />   
      </div>

      <div className="job-listings">
        <h2>Open Positions</h2>
        {/* You can loop through an array of job listings if dynamically generated */}
        <div className="job-card">
          <h3>Software Developer</h3>
          <p>Join our development team to create cutting-edge solutions.</p>
          <button className="apply-btn">Apply Now</button>
        </div>
        <div className="job-card">
          <h3>Product Designer</h3>
          <p>Help shape the user experience and design of our innovative products.</p>
          <button className="apply-btn">Apply Now</button>
        </div>
        {/* Add more job cards as needed */}
      </div>

      <div className="careers-info">
        <h2>Why Ingrov Systems?</h2>
        <p>At Ingrov Systems, we believe in a collaborative and inclusive environment where every voice matters. We offer:</p>
        <ul>
          <li>Professional growth and development opportunities</li>
          <li>Flexible work schedules</li>
          <li>Health, wellness, and financial benefits</li>
          <li>Employee recognition programs</li>
        </ul>
      </div>
    </section>
  );
};

export default Careers;