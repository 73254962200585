// LogoImage.js
import React from 'react';

const LogoImage = ({ src, alt, className, style, ...props }) => {
 return (
   <img
     src={src}
     alt={alt}
     className={className}
     style={{
       ...style,
       width: 'auto',
       height: 'auto',
       maxWidth: '80px'  
     }}
     {...props}
   />
 );
};

export default LogoImage;