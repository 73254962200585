import React from 'react';

const ResponsiveImage = ({ src, alt, className, style, width, height, ...props }) => {
  return (
    <img
      src={src}
      alt={alt}
      width={width}    
      height={height}  
      className={className}
      style={{
        ...style,
        maxWidth: '100%',  
        height: 'auto'     
      }}
      {...props}
    />
  );
};

export default ResponsiveImage;