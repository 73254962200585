import React from 'react';
import { Link } from 'react-router-dom';
import './Footer.css';
import logo from '../assets/images/11.svg'; 
import facebook from '../assets/images/4.svg'
import twitter from '../assets/images/5.svg'
import google from '../assets/images/1.svg'
import linkedin from '../assets/images/2.svg'
import youtube from '../assets/images/6.svg'

import ResponsiveImage from './ResponsiveImage.js';
import LogoImage from './LogoImage.js';



const Footer = () => {
  return (
    <footer className="footer">
      <div className="footer-left">
        <LogoImage src={logo} alt="INGROV SYSTEMS Logo" className="footer-logo" />
        <p>&copy; 2024 INGROV SYSTEMS. All rights reserved.</p>
      </div>

      <div className="footer-center">
        <p>
          <Link to="/history">About Us</Link> | 
          <Link to="/expertise">Services</Link> | 
          <Link to="/contacts">Contact</Link>
        </p>
      </div>

      <div className="footer-right">
        <a href="https://facebook.com" target="_blank" rel="noopener noreferrer">
          <ResponsiveImage loading="lazy" src={facebook} alt="Facebook" className="social-icon" />
        </a>
        <a href="https://twitter.com" target="_blank" rel="noopener noreferrer">
          <ResponsiveImage loading="lazy" src={twitter} alt="Twitter" className="social-icon" />
        </a>
        <a href="https://linkedin.com" target="_blank" rel="noopener noreferrer">
          <ResponsiveImage loading="lazy" src={linkedin} alt="LinkedIn" className="social-icon" />
        </a>
        <a href="https://youtube.com" target="_blank" rel="noopener noreferrer">
          <ResponsiveImage loading="lazy" src={youtube} alt="YouTube" className="social-icon" />
        </a>
        <a href="https://google.com" target="_blank" rel="noopener noreferrer">
          <ResponsiveImage loading="lazy" src={google} alt="Google" className="social-icon" />
        </a>
      </div>
    </footer>
  );
};

export default Footer;
