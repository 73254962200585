import React, { useEffect } from 'react';
import {
  BrowserRouter,
  Route,
  Routes,
  useLocation,
  Link,
  Navigate
} from 'react-router-dom';

// Components imports
import Header from './components/Header.js';
import Footer from './components/Footer.js';

// Pages imports
import Home from './pages/Home.js';
import Services from './pages/Services.js';
import Contact from './pages/Contact.js';
import Leadership from './pages/Leadership.js';
import History from './pages/History.js';
import Expertise from './pages/Expertise.js';
import Careers from './pages/Careers.js';

// Styles imports
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';



// 404 Page component
const PageNotFound = () => (
  <main className="not-found-container">
    <div className="not-found-content">
      <h1>404</h1>
      <p>The page you are looking for does not exist.</p>
      <Link to="/" className="back-home-link">Go back to Home</Link>
    </div>
  </main>
);

// ScrollToTop component
const ScrollToTop = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth' // Ajoute un défilement fluide
    });
  }, [pathname]);

  return null;
};

// Routes configuration
const routes = [
  { path: '/', element: <Home /> },
  { path: '/services', element: <Services /> },
  { path: '/contacts', element: <Contact /> },
  { path: '/expertise', element: <Expertise /> },
  { path: '/leadership', element: <Leadership /> },
  { path: '/history', element: <History /> },
  { path: '/careers', element: <Careers /> },
];

// Main App component
const App = () => {
  return (
    <BrowserRouter>
      <ScrollToTop />
      <div className="app-container">
        <Header />
        <main className="main-content">
          <Routes>
            {routes.map(({ path, element }) => (
              <Route key={path} path={path} element={element} />
            ))}
            {/* Redirect /contact to /contacts if needed */}
            <Route path="/contact" element={<Navigate to="/contacts" replace />} />
            <Route path="*" element={<PageNotFound />} />
          </Routes>
        </main>
        <Footer />
      </div>
    </BrowserRouter>
  );
};

export default App;