import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App.js';
import './index.css'; 

// Firebase imports et configuration
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";


const firebaseConfig = {
  apiKey: "AIzaSyBOjOA6...",
  authDomain: "ingrov-system.firebaseapp.com",
  projectId: "ingrov-system",
  storageBucket: "ingrov-system.appspot.com",
  messagingSenderId: "645848130655",
  appId: "1:645848130655:web:d2adc5f3a2e87cb38fe7ea",
  measurementId: "G-LEV0SNE3KL"
};

// Initialize Firebase
const appFirebase = initializeApp(firebaseConfig);
export const db = getFirestore(appFirebase);
console.log("Firebase initialized:", appFirebase);

// Root rendering
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);
