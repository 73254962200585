import React from 'react';
import './Expertise.css'; // Optional: If you want to add specific styles for this component
import ResponsiveImage from '../components/ResponsiveImage.js';

// Importing images
import softwareEngineeringImage from '../assets/images/software-engineering.jpeg';
import humanResourcesImage from '../assets/images/human_resources.jpeg';
import cloudComputingImage from '../assets/images/cloud-computing.jpeg';
import dataAnalysisImage from '../assets/images/4.png';
import digitalBrandingImage from '../assets/images/1.png';
import artificialIntelligenceImage from '../assets/images/2.png';
import cybersecurityImage from '../assets/images/3.png';

const services = [
  {
    title: "Software Engineering",
    description: "At Ingrov Systems, we specialize in creating custom software solutions designed to meet your business's unique needs. Our team of skilled developers works closely with you to understand your challenges and goals, delivering software that enhances efficiency, streamlines operations, and boosts performance.",
    details: (
      <div>
        <p>Our Core Software Development Services Include:</p>
        <ul>
          <li><strong>Custom Application Development:</strong> From concept to launch, we build robust, scalable, and secure applications tailored to your business requirements.</li>
          <li><strong>Enterprise Software Integration:</strong> Seamlessly integrate new software with your existing systems, ensuring a smooth and efficient workflow across your organization.</li>
          <li><strong>Mobile App Development:</strong> Expand your business reach with custom mobile apps for Android and iOS platforms, providing your customers with intuitive, high-performing apps.</li>
          <li><strong>Software Maintenance & Support:</strong> We offer ongoing support and maintenance to ensure your software stays up-to-date, secure, and fully optimized as your business evolves.</li>
        </ul>
      </div>
    ),
    image: softwareEngineeringImage,
  },
  {
    title: "Human Resources",
    description: "At Ingrov Systems, we provide innovative HR solutions designed to effectively manage and grow your team. Our approach focuses on enhancing employee engagement and optimizing HR processes to create a productive work environment.",
    details: (
      <div>
        <p>Our Comprehensive Human Resources Services Include:</p>
        <ul>
          <li><strong>Talent Acquisition:</strong> We help you attract and retain top talent through strategic recruitment processes, ensuring you find candidates who align with your company culture and goals.</li>
          <li><strong>Employee Onboarding:</strong> Our onboarding solutions streamline the integration of new hires, providing them with the necessary tools and resources to succeed from day one.</li>
          <li><strong>Performance Management:</strong> Implement effective performance evaluation systems that foster employee development and align individual goals with organizational objectives.</li>
          <li><strong>Payroll Management:</strong> Simplify your payroll processes with our automated solutions, ensuring timely and accurate compensation while maintaining compliance with regulations.</li>
          <li><strong>Employee Development & Training:</strong> Invest in your team's growth with tailored training programs that enhance skills and promote career advancement.</li>
          <li><strong>HR Compliance & Risk Management:</strong> Stay compliant with labor laws and regulations while minimizing risks associated with employee management.</li>
        </ul>
      </div>
    ),
    image: humanResourcesImage,
  },
  {
    title: "Cloud Computing",
    description: "At Ingrov Systems, we provide scalable cloud solutions designed to meet your growing business needs. Our cloud-based services enhance operational efficiency and enable seamless collaboration across teams.",
    details: (
      <div>
        <p>Our Comprehensive Cloud Computing Services Include:</p>
        <ul>
          <li><strong>Cloud Infrastructure Setup:</strong> We help you design and implement a robust cloud infrastructure that supports your business operations and scales with your growth.</li>
          <li><strong>Data Storage & Management:</strong> Utilize our secure cloud storage solutions to efficiently manage and protect your data, ensuring easy access and compliance with regulations.</li>
          <li><strong>Cloud Migration Services:</strong> Seamlessly transition your existing systems to the cloud with minimal disruption, leveraging our expertise in migration strategies.</li>
          <li><strong>Disaster Recovery Solutions:</strong> Implement reliable disaster recovery plans to safeguard your data against loss and ensure business continuity.</li>
          <li><strong>Cloud Security:</strong> Protect your cloud environment with advanced security measures, including encryption, access controls, and continuous monitoring.</li>
        </ul>
      </div>
    ),
    image: cloudComputingImage,
  },
  {
    title: "Data Analysis",
    description: "At Ingrov Systems, we specialize in transforming raw data into actionable insights that drive informed decision-making. Our data analysis services empower businesses to leverage their data effectively.",
    details: (
      <div>
        <p>Our Data Analysis Services Include:</p>
        <ul>
          <li><strong>Predictive Analytics:</strong> Use statistical algorithms and machine learning techniques to identify future trends and behaviors based on historical data.</li>
          <li><strong>Business Intelligence Reporting:</strong> Generate insightful reports and dashboards that provide a clear view of your business performance and key metrics.</li>
          <li><strong>Real-Time Data Processing:</strong> Analyze data in real-time to make timely decisions that enhance operational efficiency and responsiveness.</li>
          <li><strong>Data Visualization:</strong> Transform complex data sets into intuitive visual representations that facilitate understanding and communication.</li>
          <li><strong>Data Warehousing:</strong> Consolidate your data from various sources into a centralized repository for easier access and analysis.</li>
        </ul>
      </div>
    ),
    image: dataAnalysisImage,
  },
  {
    title: "Digital Branding",
    description: "At Ingrov Systems, we help you build a strong online presence through effective digital branding strategies. Our solutions connect your brand with its audience in meaningful ways.",
    details: (
      <div>
        <p>Our Digital Branding Services Include:</p>
        <ul>
          <li><strong>Brand Strategy Development:</strong> Craft a unique brand identity that resonates with your target audience and differentiates you from competitors.</li>
          <li><strong>Logo Design:</strong> Create visually appealing logos that encapsulate your brand's essence and values.</li>
          <li><strong>Social Media Strategy:</strong> Develop comprehensive social media plans to engage your audience, increase brand awareness, and drive conversions.</li>
          <li><strong>Content Creation:</strong> Produce high-quality content that reflects your brand's voice and engages your audience across various platforms.</li>
          <li><strong>Email Marketing Campaigns:</strong> Design targeted email campaigns that nurture leads and keep your audience informed about your offerings.</li>
        </ul>
      </div>
    ),
    image: digitalBrandingImage,
  },
  {
    title: "Artificial Intelligence",
    description: "Leverage the power of AI at Ingrov Systems to automate processes, enhance decision-making, and improve outcomes. Our AI solutions are designed to transform how you do business.",
    details: (
      <div>
        <p>Our Artificial Intelligence Services Include:</p>
        <ul>
          <li><strong>Machine Learning Solutions:</strong> Develop predictive models that learn from data patterns to improve accuracy over time.</li>
          <li><strong>NLP (Natural Language Processing):</strong> Implement AI-driven solutions for text analysis, sentiment analysis, and chatbot development to enhance customer interactions.</li>
          <li><strong>Computer Vision:</strong> Utilize AI technologies to analyze visual data for applications such as image recognition and video analytics.</li>
          <li><strong>AI Strategy Consulting:</strong> Assess your business needs and develop tailored AI strategies that align with your goals.</li>
          <li><strong>Robotic Process Automation (RPA):</strong> Automate repetitive tasks using AI-powered bots to increase efficiency and reduce operational costs.</li>
        </ul>
      </div>
    ),
    image: artificialIntelligenceImage,
  },
  { 
    title: "Cybersecurity",
    description: "INGROV SYSTEMS has been at the forefront of the fight against cyber-attacks. We offer a wide range of solutions to protect your sensitive data, infrastructure, and operations. Our team ensures that systems are highly secured and protected against potential threats. In addition to that, the company has positioned itself as an important player in cybersecurity education. Our services include:",
    details: (
      <ul>
        <li>Data Encryption & Privacy Compliance: Ensure that your data remains secure and that your business complies with the latest regulations.</li>
        <li>Vulnerability Assessments & Penetration Testing: Identify weaknesses in your network and applications before attackers do.</li>
        <li>Threat Detection & Response: Real-time monitoring and swift incident response to mitigate potential damage.</li>
        <li>Cybersecurity Training & Awareness: Empower your team to recognize and respond to potential threats, reducing human error vulnerabilities.</li>
      </ul>
    ),
    image: cybersecurityImage,
  },
];

const Expertise = () => {
  return (
    <section className="expertise">
      <h2 className="animated-heading">Capabilities</h2>
      <div className="expertise-cards">
        {services.map((service, index) => (
          <div className="expertise-card" key={index}>
            <ResponsiveImage 
              src={service.image} 
              alt={service.title} 
              className="expertise-card-image" 
            />
            <div className="expertise-card-content">
              <h3>{service.title}</h3>
              <p>{service.description}</p>
              <div>{typeof service.details === 'string' ? <p>{service.details}</p> : service.details}</div>
            </div>
          </div>
        ))}
      </div>
    </section>
  );
};

export default Expertise;