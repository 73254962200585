import React from 'react';
import './History.css'; // Optional: If you want to add specific styles for this component
import historyImage from '../assets/images/story2.jpeg'; // Import the history image
import ResponsiveImage from '../components/ResponsiveImage.js';

const History = () => {
  return (
    <section className="history">
      <div className="history-content">
        <ResponsiveImage src={historyImage} alt="Company History" className="history-image" />
        <div className="history-text">
          <h2 className="animated-heading">Our Journey</h2>
          <p>
          The company was founded in 2022, it  initially started  as an advisory firm in the mortgage industry. In the late 2022, it expanded its offerings and shifted the vision to now revolutionize the software industry by delivering innovative, reliable solutions. The company has now grown into a leading organization with the reputation for excellence and cutting-edge technology. 
          </p>
          <p>
          Our commitment to customer satisfaction , quality and continuous improvement has the driving force behind our growth. We are proud to say we serve customers across federal government and private sector.
          </p>
        </div>
      </div>
    </section>
  );
};

export default History;