import React from 'react';
import './Leadership.css'; // Optional: If you want to add specific styles for this component
import leadershipImage from '../assets/images/story1.jpeg'; // Import the leadership image
import ResponsiveImage from '../components/ResponsiveImage.js';

const Leadership = () => {
  return (
    <section className="leadership">
      <div className="leadership-content">
        <ResponsiveImage src={leadershipImage} alt="Leadership" className="leadership-image" />
        <div className="leadership-text">
          <h2 className="animated-heading">Leadership at Ingrov Systems</h2>
          <p>
          At INGROV SYSTEMS , our leadership team is comprised of visionary experts with decades of combined experience in software development, innovation, and technology strategy. Our leaders are passionate about driving growth, fostering a culture of creativity, and empowering our teams to deliver world-class solutions. We believe in leading with integrity, collaboration, and a commitment to excellence, ensuring that we not only meet but exceed our clients' expectations.
          </p>
          <p>
          Our team’s dedication to cutting-edge technology and a customer-first approach keeps us at the forefront of the software industry, enabling us to build scalable, robust, and transformative products that shape the future.

          </p>
          
        </div>
      </div>
    </section>
  );
};

export default Leadership;