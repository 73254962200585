import React, { useState } from 'react';
import './Contact.css'; // Optional: If you want to add specific styles for this component
import contactImage from '../assets/images/contact.png'; // Import the contact image
import { getDatabase, ref, set } from "firebase/database"; // Import necessary functions from Firebase Realtime Database
import ResponsiveImage from '../components/ResponsiveImage.js';

const Contact = () => {
  // State to handle form inputs
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false); // State for submission status
  const [successMessage, setSuccessMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  // Submit form to Firebase Realtime Database
  const handleSubmit = async (e) => {
    e.preventDefault(); // Prevent the default form submission behavior
    setIsSubmitting(true); // Set submission state

    try {
      const db = getDatabase(); // Get a reference to the database
      const newContactRef = ref(db, 'contacts/' + Date.now()); // Create a new reference for the contact entry

      // Set the contact details in the database
      await set(newContactRef, {
        name,
        email,
        message,
        timestamp: new Date().toISOString() // Optional: Timestamp for when the message was sent
      });

      // Reset form fields after successful submission
      setName('');
      setEmail('');
      setMessage('');
      setSuccessMessage('Message sent successfully! We will get back to you soon.');
      setErrorMessage(''); // Clear error message if any
    } catch (error) {
      console.error("Error sending message: ", error);
      setErrorMessage('Failed to send message. Please try again later.');
      setSuccessMessage(''); // Clear success message if any
    } finally {
      setIsSubmitting(false); // Reset the submission state
    }
  };

  return (
    <section className="contact">
      <div className="contact-content">
        <ResponsiveImage src={contactImage} alt="Contact Us" className="contact-image" />
        <div className="contact-text">
          <h2 className="animated-heading">Get in Touch with Us</h2>
          <p>
            At Ingrov Systems, we value your feedback and inquiries. Whether you have a question about our services, need assistance, or want to discuss a project, our team is here to help.
          </p>
          <h3>Contact Form</h3>
          <form className="contact-form" onSubmit={handleSubmit}>
            <label htmlFor="name">Name:</label>
            <input
              type="text"
              id="name"
              name="name"
              value={name}
              onChange={(e) => setName(e.target.value)}
              required
              autoComplete="name" // Added autocomplete attribute for name
            />

            <label htmlFor="email">Email:</label>
            <input
              type="email"
              id="email"
              name="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
              autoComplete="email" // Added autocomplete attribute for email
            />

            <label htmlFor="message">Message:</label>
            <textarea
              id="message"
              name="message"
              rows="4"
              value={message}
              onChange={(e) => setMessage(e.target.value)}
              required
              autoComplete="off" // Added autocomplete attribute for message (optional)
            ></textarea>

            <button
              type="submit"
              disabled={isSubmitting}
              className={successMessage ? "success-button" : ""}
            >
              {isSubmitting ? 'Sending...' : successMessage ? 'Message Successfully Sent' : 'Send Message'}
            </button>
          </form>

          <br></br>
          <br></br>
          <br></br>
          <br></br>

          <h3>Contact Information</h3>
          <p><strong>Email:</strong> info@ingrovsystems.com</p>
          <p><strong>Phone:</strong> +1 (123) 456-7890</p>
          <p><strong>Address:</strong> 123 Tech Lane, Silicon Valley, CA, USA</p>

          
          {/* Success or error message display */}
          {successMessage && <p className="success-message" aria-live="polite">{successMessage}</p>}
          {errorMessage && <p className="error-message" aria-live="polite">{errorMessage}</p>}
          
          
        </div>
      </div>
    </section>
  );
};

export default Contact;
